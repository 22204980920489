import React from 'react';
import Layout from '../../components/Layout/layout';
import AbsenceManagementArticle from '../../Views/Website/Articles/Absence-Management/absence-management';

const AbsenceManagementArticlePage: React.FC = (props: any) => {
  return (
    <Layout>
      <AbsenceManagementArticle location={props.location.href} />
    </Layout>
  );
};

export default AbsenceManagementArticlePage;
